import eventModule from '../../services/event';
import FiltersMixin from './../../mixins/filtersMixins';
import ChartPageSelector from '@/components/chartPageSelector/chartPageSelector';
import UserPropertyFilterComponent from '@/components/chartFilters/userPropertyFilter/userPropertyFilter';
import DashboardSelectorComponent from './../dashboardSelectorPopup/dashboardSelector';
import DynamicTimelineGraphComponent from '@/components/graphs/dynamicTimelineGraph/dynamicTimelineGraph';
import HorizontalBarGraph from '@/components/graphs/horizontalBarGraph/horizontalBarGraph';
import DynamicBarGraph from '@/components/graphs/dynamicBarGraph/dynamicBarGraph';
import EventFilterComponent from '@/components/chartFilters/eventFilter/eventFilter';
const moment = require('moment');

export default {
  name: 'home',
  data() {
    return {
      timezone: '+5:30',

      // Event info properties
      graphData: null,
      tableData: null,
      tableColumns: null,

      groupByDuration: 'day',
      groupByDurationList: [
        {
          key: 'Hourly',
          value: 'hour'
        },
        {
          key: 'Daily',
          value: 'day'
        },
        {
          key: 'Weekly',
          value: 'week'
        },
        {
          key: 'Monthly',
          value: 'month'
        }
      ],

      fetchingGraphData: false,

      // Chart options
      // chartType: "dynamic_horizontal_bar_graph",
      chartType: 'dynamic_timeline_graph',
      chartResultType: 'events',
      chartParams: null,
      dynamicTimelineChartOptions: null,
      dynamicBarChartOptions: null,
      horizontalBarChartOptions: null,
      selectedEventListCount: 0,
      // selectedEventList: null,

      // Timing properties
      startTime: null,
      endTime: null,
      timingFilter: '10080',
      dateFilterValues: []
    };
  },
  watch: {
    timingFilter: {
      handler: function () {
        if (this.timingFilter) {
          this.groupByDurationList = this.getGroupByDurationList(this.timingFilter);
          this.groupByDuration = this.groupByDurationList[0].value;
          this.fetchGraphData();
        }
      }
    }
  },
  mixins: [FiltersMixin],
  components: {
    ChartPageSelector,
    EventFilterComponent,
    UserPropertyFilterComponent,
    DashboardSelectorComponent,
    DynamicTimelineGraphComponent,
    DynamicBarGraph,
    HorizontalBarGraph
  },
  methods: {
    showSelectDashboardPopup() {
      this.$refs.dashboardSelector.dialogFormVisible = true;
    },

    clearEventFilter() {
      this.$refs.eventFilterRef.selectedEventList = [];
      this.$refs.eventFilterRef.currentEvent = {
        name: null,
        property: null
      };
      this.$refs.userFilterComponent.clearFilter();
      this.graphData = null;
      this.fetchingGraphData = false;
      this.selectedEventListCount = 0;

      // clear graph objects
      this.dynamicTimelineChartOptions = null;
      this.horizontalBarChartOptions = null;
      this.dynamicBarChartOptions = null;
    },

    //#region ----------------------------- Graph render methods --------------------

    drawGraph() {
      let durationType = 'latest';
      if (this.timingFilter == '') durationType = 'exact';

      if (this.chartType == 'dynamic_bar_graph') {
        this.dynamicBarChartOptions = {
          data: this.graphData,
          durationGroup: this.groupByDuration,
          durationType: durationType,
          duration: this.timingFilter,
          startTime: this.startTime,
          endTime: this.endTime
        };
        // console.log(JSON.stringify(this.dynamicBarChartOptions));
        this.fetchingGraphdata = false;
      } else if (this.chartType == 'dynamic_timeline_graph') {
        this.dynamicTimelineChartOptions = {
          data: this.graphData,
          durationGroup: this.groupByDuration,
          durationType: durationType,
          duration: this.timingFilter,
          startTime: this.startTime,
          endTime: this.endTime
        };
        // console.log(JSON.stringify(this.dynamicTimelineChartOptions));
      } else if (this.chartType == 'dynamic_horizontal_bar_graph') {
        this.horizontalBarChartOptions = {
          data: this.graphData,
          durationGroup: this.groupByDuration,
          durationType: durationType,
          duration: this.timingFilter,
          startTime: this.startTime,
          endTime: this.endTime
        };
      }

      requestAnimationFrame(() => {
        this.renderTableData();
      });
    },

    renderTableData() {
      let data = null;
      if (this.chartType == 'dynamic_bar_graph') {
        data = this.$refs.barGraph.getDataAsTable();
      } else if (this.chartType == 'dynamic_timeline_graph') {
        data = this.$refs.timelineGraph.getDataAsTable();
      } else if (this.chartType == 'dynamic_horizontal_bar_graph') {
        data = this.$refs.hbarGraph.getDataAsTable();
      }

      this.tableColumns = data.columns;
      this.tableData = data.data;
    },

    exportCsv() {
      if (this.chartType == 'dynamic_bar_graph') {
        this.$refs.barGraph.exportCSV();
      } else if (this.chartType == 'dynamic_timeline_graph') {
        this.$refs.timelineGraph.exportCSV();
      } else if (this.chartType == 'dynamic_horizontal_bar_graph') {
        this.$refs.barGraph.exportCSV();
      }
    },

    fetchGraphData() {
      // Remove old graph data
      this.graphData = null;
      this.selectedEventListCount = this.$refs.eventFilterRef.selectedEventList.length;
      if (this.selectedEventListCount == 0) {
        this.dynamicTimelineChartOptions = null;
        this.horizontalBarChartOptions = null;
        this.dynamicBarChartOptions = null;

        this.warningToast('Please select event to check trends!');
        return;
      }

      //Build event filters
      let eventList = [];
      this.$refs.eventFilterRef.selectedEventList.forEach((eventFilter) => {
        // Process group bys
        let groupByList = [];
        eventFilter.groupByList.forEach((grupByInfo) => {
          if (grupByInfo.propertyColumn != null) groupByList.push(grupByInfo.propertyColumn);
        });

        // Process proeprty filters
        let propertyFilterList = [];
        eventFilter.filters.forEach((propertyFilter) => {
          if (propertyFilter.propertyInfo == null || propertyFilter.value == null) {
            return;
          }

          propertyFilterList.push({
            comparisionType: propertyFilter.comparisionType,
            propertyColumn: propertyFilter.propertyColumnName,
            property: propertyFilter.propertyName,
            value: propertyFilter.value
          });
        });
        // eventList[eventFilter.name].filters = eventList[eventFilter.name].filters.concat(propertyFilterList);

        eventList.push({
          eventName: eventFilter.name,
          groupByList: groupByList,
          filters: propertyFilterList
        });
      });

      // Build timing filters
      if (this.timingFilter === '') {
        this.startTime = moment(this.dateFilterValues[0]);
        this.endTime = moment(this.dateFilterValues[1]);
        this.durationType = 'exact';
      } else {
        this.startTime = moment().subtract(this.timingFilter, 'minutes');
        this.endTime = moment();
        this.durationType = 'latest';
      }

      // Read user filters
      let userFilters = this.$refs.userFilterComponent.getFilters();
      // console.log('user filters', userFilters);

      if (!userFilters) {
        return;
      }

      let params = {
        filters: {
          eventFilters: eventList,
          userFilters: userFilters
        },
        startTime: this.startTime.format('YYYY-MM-DD HH:mm:ss'),
        endTime: this.endTime.format('YYYY-MM-DD HH:mm:ss'),
        durationGroup: this.groupByDuration,
        durationType: this.durationType,
        duration: this.timingFilter,
        resultType: this.chartResultType,
        version: 1
      };
      this.chartParams = params;

      if (this.chartType == 'dynamic_bar_graph' || this.chartType == 'dynamic_horizontal_bar_graph') {
        // Send request to server
        this.fetchingGraphData = true;
        eventModule
          .getEventDynamicBarGraphData(params, this)
          .then((result) => {
            this.graphData = result.data.data;
            this.drawGraph();
            this.fetchingGraphData = false;
          })
          .catch((err) => {
            this.fetchingGraphData = false;
            this.reportError(err);
          });
      } else if (this.chartType == 'dynamic_timeline_graph') {
        // Send request to server
        this.fetchingGraphData = true;
        eventModule
          .getEventDynamicTimelineGraphData(params, this)
          .then((result) => {
            this.graphData = result.data.data;
            this.drawGraph();
            this.fetchingGraphData = false;
          })
          .catch((err) => {
            this.fetchingGraphData = false;
            this.errorToast('Something is wrong, please contact support.');
            this.reportError(err);
          });
      }
    }

    //#endregion ----------------------------- Graph render methods --------------------
  },

  created() {
    this.dateFilterValues = [moment().subtract(7, 'days').valueOf(), moment()];
    this.timezone = this.$store.state.token.timezone ? this.$store.state.token.timezone : '+5:30';
    this.startTime = moment().subtract(this.timingFilter, 'minutes');
    this.endTime = moment();

    // set group by list
    this.groupByDurationList = this.getGroupByDurationList(this.timingFilter);
    this.groupByDuration = this.groupByDurationList[0].value;
    // this.$router.go(this.$router.currentRoute)
  }
};

<template>
  <div id="eventFilter">
    <!-- Event Filters -->
    <div class="eventFilter" :key="index" v-for="(eventFilter, index) in selectedEventList">
      <!-- Header -->
      <div class="filterHeader">
        <div class="numberContainer">{{ index + 1 }}</div>
        {{ eventFilter.name }}
        <el-button class="addFilterButton deleteBtn" icon="el-icon-delete" @click="deleteEventFilter(index)" type="text" size="mini"></el-button>
        <el-button v-if="eventFilter.properties" class="addFilterButton" icon="el-icon-plus" type="text" size="mini" @click="addPropertyGroupBy(eventFilter)">Group By</el-button>
        <el-button v-if="eventFilter.properties" class="addFilterButton" icon="el-icon-plus" type="text" size="mini" @click="addPropertyFilter(eventFilter)">Filter</el-button>
      </div>

      <!-- Filter Of Particular Event -->
      <div class="propertyFilterList" v-if="eventFilter.filters.length > 0">
        <div class="propertyFilter" v-for="(subFilter, index1) in eventFilter.filters" :key="index1">
          <span style="font-size: 12px; color: #444; line-height: 26px"> <span v-if="index1 != 0">and</span>where &nbsp; </span>

          <!-- Select Filter Property -->
          <el-select class="smallDropdown" size="mini" v-model="subFilter.propertyColumnName" filterable @change="onFilterPropertyChange(subFilter, eventFilter.properties)" placeholder="Select Property">
            <!-- <el-option v-for="(property, index) in eventFilter.properties" :key="index" :label="property.name" :value="property.name"></el-option> -->
            <el-option-group v-for="eventPropGroup in eventFilter.properties" :key="eventPropGroup.columnName" :label="eventPropGroup.label">
              <el-option v-for="(property, index) in eventPropGroup.options" :key="index" :label="property.name" :value="property.columnName"> </el-option>
            </el-option-group>
          </el-select>

          <!-- Select Comparision Type -->
          <el-select class="smallDropdown" size="mini" v-if="subFilter.comparisionTypeList" v-model="subFilter.comparisionType" filterable placeholder="Select Event Property">
            <el-option v-for="(compType, index2) in subFilter.comparisionTypeList" :key="index2" :label="compType.label" :value="compType.key"></el-option>
          </el-select>

          <!-- Input Field List Based on types -->
          <div class="filterInputContainer" v-if="subFilter.comparisionType && subFilter.comparisionType != 'is not null' && subFilter.comparisionType != 'is null'">
            <!-- Render Date For Date Type -->
            <div v-if="subFilter.propertyInfo.type == 'date'">
              <el-date-picker size="mini" v-model="subFilter.value" type="datetime" placeholder="Select date and time"> </el-date-picker>
            </div>

            <!-- Render Number input for number type -->
            <div v-if="subFilter.propertyInfo.type == 'decimal' || subFilter.propertyInfo.type == 'number' || subFilter.propertyInfo.type == 'bigint'">
              <el-input size="mini" placeholder="Enter value" v-model="subFilter.value"></el-input>
            </div>

            <!-- Render Searchable String for string type  -->
            <div v-if="subFilter.propertyInfo.type == 'string'">
              <el-autocomplete autocomplete="off" size="mini" placeholder="Enter search term" @focus="setCurrentEventData(eventFilter.name, subFilter)" v-model="subFilter.value" :fetch-suggestions="fetchEventPropertySuggestion" clearable></el-autocomplete>
            </div>

            <!-- Boolean - Yes/No dropdown -->
            <div v-if="subFilter.propertyInfo.type == 'boolean'">
              <el-select size="mini" v-model="subFilter.value">
                <el-option label="True/Yes" value="true"></el-option>
                <el-option label="False/No" value="false"></el-option>
              </el-select>
            </div>
          </div>

          <!-- Delete Button -->
          <el-button class="addFilterButton deleteBtn" icon="el-icon-delete" @click="deletePropertyFilter(eventFilter.filters, index1)" type="text" size="mini"></el-button>
        </div>
      </div>

      <!-- Group Bys of perticular event -->
      <div class="propertyFilterList" v-if="eventFilter.groupByList.length > 0">
        <div class="propertyFilter" v-for="(groupBy, index1) in eventFilter.groupByList" :key="index1">
          <span style="font-size: 12px; color: #444; line-height: 26px">
            <span v-if="index1 != 0">and</span>
            <span v-else>group by</span> &nbsp;
          </span>

          <!-- Select Filter Property -->
          <el-select class="smallDropdown" size="mini" v-model="groupBy.propertyColumn" filterable placeholder="Select Property">
            <!-- <el-option v-for="(property) in eventFilter.properties" :key="property.name" :label="property.name" :value="property.name"></el-option> -->
            <el-option-group v-for="eventPropGroup in eventFilter.properties" :key="eventPropGroup.label" :label="eventPropGroup.label">
              <el-option v-for="(property, index) in eventPropGroup.options" :key="index" :label="property.name" :value="property.columnName"> </el-option>
            </el-option-group>
          </el-select>

          <!-- Delete Button -->
          <el-button class="addFilterButton deleteBtn" icon="el-icon-delete" @click="deletePropertyGroupBy(eventFilter.groupByList, index1)" type="text" size="mini"></el-button>
        </div>
      </div>
    </div>

    <!-- Loader for fetching data -->
    <div class="loader" v-if="fetchingData">
      <beat-loader :loading="fetchingData" color="rgb(220, 223, 230)" size="8px"></beat-loader>
    </div>

    <!-- Add event filter button -->
    <el-select class="addNewEventBtn" v-if="selectedEventList.length < 5 && !fetchingData" v-model="currentEvent.name" filterable @change="onEventSelect()" placeholder="Select Event To Check Trends">
      <el-option-group v-for="eventGroup in eventList" :key="eventGroup.label" :label="eventGroup.label">
        <el-option v-for="eventName in eventGroup.options" :key="eventName" :label="eventName" :value="eventName"> </el-option>
      </el-option-group>
    </el-select>
    <div class="onlyTenStepsAllowed" v-else>Only 5 event step can be analyzed.</div>
  </div>
</template>

<style lang="scss" src="./eventFilter.scss"></style>

<script>
import EventFilterComponent from './eventFilterComponent';
export default EventFilterComponent;
</script>
